const domNodeAliases = {
  findAll: "querySelectorAll",
  find: "querySelector",
  set: "setAttribute",
  get: "getAttribute",
  on: "addEventListener"
};

export var dom = {
  find() {
    return document.querySelector.apply(document, arguments);
  },

  findAll() {
    return document.querySelectorAll.apply(document, arguments);
  },

  createElement(tagName, attributes) {
    if (attributes == null) { attributes = {}; }
    const element = document.createElement(tagName);
    for (let key in attributes) { const value = attributes[key]; element.setAttribute(key, value); }
    return element;
  },

  install(window) {
    for (let alias of Object.keys(domNodeAliases)) {
      const original = domNodeAliases[alias];
      window.Element.prototype[alias] = window.Element.prototype[original];
      window.Document.prototype[alias] = window.Document.prototype[original];
      window.DocumentFragment.prototype[alias] = window.DocumentFragment.prototype[original];
    }

    window.NodeList.prototype.on = function() { Array.from(this).map((node) => node.on(...arguments)); };

    window.Element.prototype.hide = function() { this.style.display = "none"; };
    window.NodeList.prototype.hide = function() { Array.from(this).map((element) => element.hide()); };

    window.Element.prototype.show = function() { this.style.display = "block"; };
    window.NodeList.prototype.show = function() { Array.from(this).map((element) => element.show()); };
  }
};
