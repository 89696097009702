import { dom } from "./dom";
import { getCookie, setCookie } from "../common/utils/cookie";

export const updateConsentFromCookie = function() {
  switch (readCookiesConsentCookie()) {
    case "accepted":
      acceptTracking();
      break;
    case "refused":
      refuseTracking();
      break;
    default:
      refuseTracking();
      break;
  }
}

export const handleCookieConsentHandlers = function() {
  updateConsentFromCookie();
  showCookiesConsentStatus();
  attachAccept();
  attachRefuse();
};

var attachAccept = () => dom.findAll("a[data-cookie-consent='accept']").on("click", function(event) {
  writeCookiesConsentCookie("accepted");
  updateConsentFromCookie();
  showCookiesConsentStatus();
  event.preventDefault();
});

var attachRefuse = () => dom.findAll("a[data-cookie-consent='refuse']").on("click", function(event) {
  writeCookiesConsentCookie("refused");
  window.location.reload(true);
  event.preventDefault();
});

const consentCookieName = "cookieAcceptStatusV2"

var writeCookiesConsentCookie = value => // NOTE: Experation is set to 10 years. Safari caps expiration to 7 days. See https://www.cookiestatus.com/
  setCookie(consentCookieName, value, {expires: 365 * 10});

const readCookiesConsentCookie = () => getCookie(consentCookieName);

const refuseTracking = function() {
  if (typeof gtag === "function") {
    gtag("consent", "update", {
      ad_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      analytics_storage: 'denied',
    });
  }
  if (typeof fbq === "function") {
    fbq("consent", "revoke");
  }
};

const acceptTracking = function() {
  if (typeof gtag === "function") {
    const consent = {
      ad_storage: 'granted',
      ad_user_data: 'granted',
      ad_personalization: 'granted',
      analytics_storage: 'granted',
    };
    gtag("consent", "update", consent);
    gtag("event", "consent_updated", consent);
  }
  if (typeof fbq === "function") {
    fbq("consent", "grant");
  }
};

var showCookiesConsentStatus = function() {
  dom.find("#cookies-accepted").hide();
  dom.find("#cookies-refused").hide();
  dom.find("#cookies-unknown").hide();

  switch (readCookiesConsentCookie()) {
    case "accepted":
      dom.find("#cookies-accepted").show();
      break;
    case "refused":
      dom.find("#cookies-refused").show();
      break;
    default:
      dom.find("#cookies-unknown").show();
      break;
  }
};
