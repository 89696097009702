/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
import Cookies from "js-cookie";

// Read more at https://developer.mozilla.org/en-US/docs/Web/HTTP/Cookies
// * httpOnly & secure: https://developer.mozilla.org/en-US/docs/Web/HTTP/Cookies#Restrict_access_to_cookies
// * domain: https://github.com/js-cookie/js-cookie#domain
// * sameSite: https://developer.mozilla.org/en-US/docs/Web/HTTP/Cookies#SameSite_attribute
// * path: https://developer.mozilla.org/en-US/docs/Web/HTTP/Cookies#Path_attribute

export var setCookie = (name, value, { expires }) => Cookies.set(
  name,
  value, {
  expires,
  sameSite: "Lax",
  secure: false,
  path: "/"
}
);

export var getCookie = name => Cookies.get()[name];
